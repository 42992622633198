<template>
    <div id="index">
        <div class="container">
            <div class="top_text">
                <p>你的位置: 首页 / 购物车 / 确认下单 / <span v-if="form.pay_type == 'wechat'">微信支付</span><span v-else>支付宝支付</span></p>
            </div>
            <div class="content_individual" v-if="pay_status == 0 || pay_show">
                <div class="content_details_text">
                    <div style="font-size: 0.3rem;">订单支付</div>
                    <!-- <div v-if="form.pay_type == 'wechat'">微信支付</div>
                    <div v-else>支付宝支付</div> -->
                </div>
                <div style="text-align: center;margin: 0.24rem 0 0.3rem;font-size: 0.14rem;color: #666666;">
                    订单提交成功，请尽快付款
                </div>
                <div style="text-align: center;margin: 0.24rem 0 0.3rem;font-size: 0.24rem;color: #4B83F0;">
                    应付金额<span style="font-size: 0.32rem;font-weight: normal;">￥{{ form.totalPrice }}</span>
                </div>
                <div class="flex flex-child-center flex-justify-content-center">
                    <!-- <div id="qrcode" ref="qrcode"></div> -->
                    <img :src="payimg" style="margin-right: 0.05rem;">
                </div>
                <div style="text-align: center;margin: 0.24rem 0 0;font-size: 0.18rem;color: #666666; font-weight: 500;">
                    <!-- 请打开<span v-if="form.pay_type == 'wechat'">微信支付</span><span v-else>支付宝支付</span>扫一扫 -->
                    请打开微信扫一扫
                </div>
                <div style="text-align: center;font-size: 0.18rem;color: #666666;font-weight: 500;">
                    扫描二维码进入小程序支付
                </div>
            </div>
            <div class="content_individual" v-if="pay_status == 1">
                <div class="flex flex-justify-content-center" style="margin: 0.5rem auto; margin-top: 10.2rem;"><img style=" width: 0.8rem;height: 0.8rem;" src="../assets/images/success.png"></div>
                <div style="font-size: 0.28rem;font-weight: 500;color: #333333;text-align: center;">支付成功</div>
                <div style="font-size: 0.18rem;font-weight: 400;color: #666666;text-align: center;">微信付款</div>
                <div class="flex flex-child-center button_error flex-justify-content-center" style="margin: 0.5rem auto;">
                    <div style="margin-right: 0;cursor: pointer;" @click="order">查看订单</div>
                </div>
            </div>
            <div class="content_individual" v-if="pay_status == 2 && !pay_show">
                <div class="flex flex-justify-content-center" style="margin: 0.5rem auto;margin-top: 10.2rem;"><img style="width: 0.8rem;height: 0.8rem;" src="../assets/images/error.png"></div>
                <div style="font-size: 0.28rem;font-weight: 500;color: #333333;text-align: center;">支付失败</div>
                <div style="font-size: 0.18rem;font-weight: 400;color: #666666;text-align: center;">支付遇到问题，请尝试重新支付</div>
                <div class="flex flex-child-center button_error flex-justify-content-center" style="margin: 0.5rem auto;">
                    <div style="cursor: pointer;" @click="newPay">重新支付</div>
                    <div style="cursor: pointer;" @click="order">查看订单</div>
                </div>
            </div>
            <div class="bottom_text">{{ $store.state.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "pay",
    data() {
        return {
            form: {},
            pay_status: 0,
            pay_show: false,
            payimg: ''
        };
    },
    computed: {
    },
    created() {
        // // 设置定时器
        // let timer = setInterval(() => {
        //     this.queryOrder()
        // }, 500);
        // 离开当前页面时销毁定时器
        // this.$once('hook:beforeDestroy', () => {
        //     clearInterval(timer);
        //     timer = null;
        // })
    },
    watch: {
    },
    async mounted() {
        this.form = this.$store.state.pay
        // console.log('this.form', this.form)
        // this.$store.commit('settab', 2)
        this.$nextTick(() => {
            this.qrcode()
        })
    },
    methods: {
        /***
         * 重新支付
         */
        newPay() {
            this.pay_show = true
            this.qrcode()
        },
        order() {
            let a = {
                order_num: this.form.data.order_num
            }
            this.$router.push({
                name: 'orderDetails',
                query: a
            })
        },
        /***
         * 查询订单状态
         */
        queryOrder() {
            this.$axios.post('api/kuerp/order/index/orderPayStatus', this.$qs.stringify({
                order_num: this.form.data.order_num,
            })).then(res => {
                if (res.data.code == 200) {
                    this.pay_status = res.data.data.pay_status
                    if (this.pay_status == 2) {
                        this.pay_show = false
                    }
                    // this.pay_status = 2
                }
            })
        },
        /***
         * 生成二维码
         */
        qrcode() {
            this.$axios.post('api/kuerp/order/index/getPayQrcode', this.$qs.stringify({
                order_num: this.form.data.order_num,
                pay_type: this.form.pay_type ? this.form.pay_type : this.form.data.pay_type,
            })).then(res => {
                if (res.data.code == 200) {
                    this.payimg = res.data.data
                    // let qrcode = new QRCode("qrcode", {
                    //     width: 200, // 二维码宽度，单位像素
                    //     height: 200, // 二维码高度，单位像素
                    //     text: res.data.data
                    // })
                }
            })

        }
    },
}
</script>

<style scoped lang="scss">
.button_error {
    width: 20%;

    >div:nth-child(1) {
        width: 1.8rem;
        height: 0.5rem;
        background: #4B83F0;
        border-radius: 0.02rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.5rem;
        text-align: center;
        margin-right: 0.2rem;
    }

    >div:nth-child(2) {
        width: 1.8rem;
        height: 0.5rem;
        border: 0.01rem solid #4B83F0;
        border-radius: 0.02rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #4B83F0;
        line-height: 0.5rem;
        text-align: center;
    }
}

#index {
    //width: 100%;
    //height: 100vh;
    background: #434655;
}

.content_individual {
    width: 100%;
    height: calc(100% - 18%);
    overflow: hidden;
    overflow-y: auto;
    background: #FFFFFF;
    padding: 0.2rem;

    .content_details_text {
        font-size: 0.18rem;
        font-weight: 500;
        color: #333333;
    }
}

.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 0.5rem;
}

.container {
    width: 90%;
    margin: 0 auto;
    height: calc(100vh - 18%);

}

.top_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    line-height: 0.2rem;
}
</style>
